import React from 'react';

import SEO from '../components/SEO';

const NotFoundPage = () => {
  return (
    <>
      <SEO seo={{ tags: [{ tagName: 'title', content: 'Sivua ei löydy.' }] }} />
      <h1>404</h1>
    </>
  );
};

export default NotFoundPage;
